import { useLayoutEffect, useRef } from "react";
import * as stratis from "stratis-ui-icons";

type IconComponentProps = {
  name: string;
  size?: number;
  color?: string;
};
export function IconComponent({ name, size = 16, color = undefined }: IconComponentProps) {
  const { completeIconSet, ...icons } = stratis;
  const icon = icons[name as keyof typeof icons] || icons["suLightbulb01"];
  const myRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (myRef.current) {
      myRef.current.innerHTML = icon.data.replace(/fill="[^"]*"/, 'fill="#FFFFFF00"');
    }
  }, [myRef, icon.data]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "0.5rem",
        cursor: "pointer",
      }}
      onClick={() => {
        navigator.clipboard.writeText(name);
        alert(`Icon "${name}" copied to clipboard!`);
      }}
    >
      <div
        ref={myRef}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: `${size}px`,
          height: `${size}px`,
          backgroundColor: color || "transparent",
        }}
      >
        {icon.data}
      </div>
      <p>{name}</p>
    </div>
  );
}
