import { useState } from "react";
import * as stratis from "stratis-ui-icons";
import { IconComponent } from "widgets/icon-component";

export function HomePage() {
  const { completeIconSet, ...icons } = stratis;

  const [searchTerm, setSearchTerm] = useState("");

  const filteredIcons = Object.keys(icons).filter((iconName) => iconName.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      <h1>Available icons</h1>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
        <input
          type="text"
          placeholder="Search icons..."
          onChange={handleSearchChange}
          value={searchTerm}
          style={{ padding: "0.5rem", marginRight: "0.5rem", borderRadius: "4px", border: "1px solid #ccc" }}
        />
        {/* <button style={{ padding: "0.5rem 1rem", borderRadius: "4px", backgroundColor: "#007bff", color: "#fff", border: "none" }}>Search</button> */}
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "1rem" }}>
        {filteredIcons.map((iconName) => (
          <IconComponent key={iconName} name={iconName} size={32} />
        ))}
      </div>
    </div>
  );
}
